import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import Instructions from '../components/instructions'
import RecentArticles from '../components/recent-articles'
import NewsLetterForm from '../components/newsLetterForm'

const RootIndex = (props) => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const posts = get(props, 'data.allContentfulPost.edges')
  const [home] = get(props, 'data.allContentfulHome.edges')
  const [instructions] = get(props, 'data.allContentfulInstructions.edges')
  console.log(props)
  return (
    <Layout location={props.location}>
      <div className="pb-1" style={{ background: '#fff' }}>
        <Helmet title={siteTitle} />
        <Hero />
        <Instructions instructions={instructions.node} />

        <div className="wrapper pl-3 pr-3">
          <hr />
          <h2 className="section-headline font-weight-bold">Videos</h2>
          <RecentArticles articles={posts} />
          <div className="d-flex justify-content-between  mt-2">
            <Link className="btn btn-primary" to="/post">
              Hier gibt's noch mehr!
            </Link>
            <NewsLetterForm />
          </div>
        </div>

        <div className="wrapper pl-3 pr-3">
          <hr />
        </div>
        <div className="wrapper pl-3 pr-3">
          <hr />
          <p className="mb-2 text-muted">{home.node.sendMeYourWishesViaMail}</p>
          <p className="mb-2 text-muted font-weight-bold">
            {home.node.donations}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPost(
      sort: { fields: [createdAt], order: ASC }
      filter: { showPostInHome: { eq: true } }
    ) {
      edges {
        node {
          title
          slug
          subtitle
          intro
          linkToPostLabel
          createdAt(formatString: "DD | MM | YYYY")
        }
      }
    }
    allContentfulHome {
      edges {
        node {
          sendMeYourWishesViaMail
          donations
        }
      }
    }
    allContentfulInstructions {
      edges {
        node {
          intro
          title
          subtitle
          linkToInstructionsLabel
        }
      }
    }
  }
`
