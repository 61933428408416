import React from 'react'
import { Link } from 'gatsby'
import { DonationLink } from './donationButton'
const Instructions = ({ instructions }) => (
  <div className="pl-3 pr-3 pt-3 d-flex align-items-end">
    <div>
      <p className="mb-2 text-muted">{instructions.subtitle}</p>
      <h2 className="section-headline font-weight-bold">
        {instructions.title}
      </h2>
      <p className="font-weight-bold mb-0">
        {instructions.intro}{' '}
        <Link to="/instructions">{instructions.linkToInstructionsLabel}</Link>
      </p>
    </div>
    <div className="vl"></div>
    <DonationLink />
  </div>
)

export default Instructions
