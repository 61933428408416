import React from 'react'

import gif from '../images/onlineyoga.gif'
import styles from './hero.module.css'

export default () => (
  <div className={styles.hero}>
    <img className={styles.heroImage} src={gif} />
  </div>
)
