import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'gatsby'
const NewsLetterForm = () => {
  return (
    <>
      <Link to="/newsletter">
        <Button variant="primary">Newsletter</Button>
      </Link>
    </>
  )
}
export default NewsLetterForm
